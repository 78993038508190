import { smartCompanySearch, smartTalentSearch } from '../../util/api';
import { storableError } from '../../util/errors';

export const RESULT_PAGE_SIZE = 24;
// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SmartSearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SmartSearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SmartSearchPage/SEARCH_LISTINGS_ERROR';
export const SWITCH_STATUS_REQUEST = 'app/SmartSearchPage/SWITCH_STATUS_REQUEST';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  usersWithTransactions: [],
  loopPageResultIds: [],
  loopPageUserWithTransactions: [],
  page: 1,
  searchStatus: 'default',
  searchResults: [],
};

const resultIds = data => data.data.filter(e => !e.attributes?.deleted).map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
        searchStatus: 'searching',
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: state.loopPageResultIds,
        pagination: payload.data.meta,
        searchInProgress: false,
        searchStatus: 'result',
        searchResults: payload.data,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
        searchStatus: 'no',
      };

    case SWITCH_STATUS_REQUEST:
      return {
        ...state,
        searchStatus: 'default',
        searchResults: [],
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
  //   payload: { searchParams },
});

export const searchListingsSuccess = response => {
  return {
    type: SEARCH_LISTINGS_SUCCESS,
    payload: { data: response.data },
  };
};

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const switchStatusRequest = () => ({
  type: SWITCH_STATUS_REQUEST,
  //   payload: { searchParams },
});

export const smartSearchCompanyEndpoint = props => {
  return async (dispatch, getState, sdk) => {
    try {
      dispatch(searchListingsRequest());

      const listingResponse = await smartCompanySearch(props);
      console.log(listingResponse);
      // const listingFields = listing?.listingFields;
      // const sanitizeConfig = { listingFields };

      // dispatch(addMarketplaceEntities(listingResponse, sanitizeConfig));
      dispatch(searchListingsSuccess(listingResponse));
    } catch (error) {
      dispatch(searchListingsError(storableError(error)));
      console.error('Error:', error);
      return { error: true };
    }
  };
};

export const switchStatus = props => {
  return async (dispatch, getState, sdk) => {
    try {
      dispatch(switchStatusRequest());
    } catch (error) {
      console.error('Error:', error);
      return { error: true };
    }
  };
};

export const smartSearchTalentEndpoint = props => {
  return async (dispatch, getState, sdk) => {
    try {
      dispatch(searchListingsRequest());

      const listingResponse = await smartTalentSearch(props);
      console.log(listingResponse);
      // const listingFields = listing?.listingFields;
      // const sanitizeConfig = { listingFields };

      // dispatch(addMarketplaceEntities(listingResponse, sanitizeConfig));
      dispatch(searchListingsSuccess(listingResponse));
    } catch (error) {
      dispatch(searchListingsError(storableError(error)));
      console.error('Error:', error);
      return { error: true };
    }
  };
};

export const loadData = (params, search, config) => dispatch => {
  return Promise.all([dispatch(switchStatusRequest())]);
};
