import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-final-form';
import { ValidationError } from '..';
import minusSVG from '../../assets/ic_baseline-minus.svg';
import plusSVG from '../../assets/ic_baseline-plus.svg';

import css from './FieldNumber.module.css';

const FieldNumberComponent = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    max,
    min,
    step,
    customClassName,
    placeholder,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames({
    [selectClassName]: selectClassName,
    [css.selectError]: hasError,
  });
  const classes = classNames(rootClassName || css.root, className);

  const updateValue = (newValue, changeType, custom) => {
    let updateValue = changeType === 'increase' ? newValue + step : newValue - step;

    const limitedValue = custom
      ? Math.max(min, Math.min(max, updateValue))
      : changeType === 'increase'
      ? Math.max(min, Math.min(max, updateValue - 1))
      : Math.max(min, Math.min(max, updateValue + 1));
    input.onChange(limitedValue);
  };

  const increase = () => {
    updateValue(Number(input.value), 'increase', true);
  };

  const decrease = () => {
    updateValue(Number(input.value), 'decrease', true);
  };

  const handleInputChange = e => {
    const newValue = Number(e.target.value) || 0;

    const changeType = newValue > Number(input.value) ? 'increase' : 'decrease';
    updateValue(newValue, changeType, false);
  };

  return (
    <div className={classes}>
      {label ? (
        <label htmlFor={id} className={css.label}>
          {label}
        </label>
      ) : null}
      <div className={classNames(css.displayFlex, customClassName)}>
        <input
          className={selectClasses}
          placeholder={placeholder}
          {...input}
          onChange={handleInputChange}
          readOnly // Prevent manual typing
        />

        <div className={classNames(css.displayFlex,css.gapWithCursor)}>
          <div onClick={decrease}>
            <img src={minusSVG} />
          </div>
          <div onClick={increase}>
            <img src={plusSVG} />
          </div>
        </div>
       
      </div>

      <ValidationError fieldMeta={meta} />
    </div>
  );
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldNumberComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
};

const { string, object, node } = PropTypes;

FieldNumberComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const FieldNumber = props => {
  return <Field component={FieldNumberComponent} {...props} />;
};

export default FieldNumber;
