import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Field } from 'react-final-form';
import { ValidationError } from '..';
import upArrowSVG from '../../assets/up-arrow.svg';
import downArrowSVG from '../../assets/down-arrow.svg';

import css from './MultipleFieldSelect.module.css';
import { FormattedMessage } from 'react-intl';

const MultipleFieldSelectComponent = props => {
  const [open, setOpen] = useState(false);
  const multipleRef = useRef();
  const dropdownRef = useRef();
  const selectRef = useRef(); // Reference for the select box
  const [position, setPosition] = useState('bottom'); // Default position

  useEffect(() => {
    if (open && dropdownRef.current && selectRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const selectRect = selectRef.current.getBoundingClientRect();
      const viewportHeight = window.innerHeight *0.88;
      // const selectedHeight = selectRect.bottom + dropdownRect.height ;
      const selectedHeight = selectRect.bottom + dropdownRect.height;
      // Check if there's enough space below
      if (selectedHeight > viewportHeight) {
        setPosition('top'); // Move dropdown above
      } else {
        setPosition('bottom'); // Default: below input
      }
    }
  }, [open]); // Run this effect only when `open` changes

  const toggleDropdown = () => {
    setOpen(prev => !prev); // Toggle open state
  };

  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    labelClassName,
    options,
    ...rest
  } = props;

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (multipleRef.current && !multipleRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const addOption = option => {
    if (option === 'all') {
      const filteOptionAll = options.filter(o => o.value !== 'all').map(option => option.value);
      input.onChange([...filteOptionAll]);
    } else {
      if (!input.value.includes(option)) {
        input.onChange([...input.value, option]);
      }
      if (input.value.includes(option)) {
        const filteredValue = input.value.filter(v => v !== option);
        input.onChange([...filteredValue]);
      }
    }

    setOpen(false);
  };

  const removeOption = option => {
    input.onChange(input.value.filter(item => item !== option.value));
  };

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const removeAllOption = option => {
    input.onChange([]);
  };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes} ref={multipleRef}>
      {label ? (
        <label htmlFor={id} className={labelClassName}>
          {label}
        </label>
      ) : null}
      {/* Custom Select Box */}
      <div className={css.fieldSelect} onClick={toggleDropdown} ref={selectRef}>
        {input.value.length > 0 ? (
          input.value.map(value => {
            const option = options.find(o => o.value === value);
            return (
              <span key={value} className={css.fieldTag}>
                {option?.label || value} {/* Display label if available */}
                <button
                  type="button"
                  onClick={event => {
                    event.stopPropagation(); // Prevent dropdown toggle
                    removeOption(option);
                  }}
                  className={css.fieldTagRemove}
                >
                  ✖
                </button>
              </span>
            );
          })
        ) : (
          <span className={css.fieldPlaceholder}>
            <FormattedMessage id="MultipleFieldSelect.placeholder" />
          </span>
        )}

        {/* Clear All Button */}
        {input.value.length > 0 && (
          <button
            type="button"
            onClick={event => {
              event.stopPropagation(); // Prevent dropdown toggle
              removeAllOption();
            }}
            className={css.fieldClear}
          >
            ✖
          </button>
        )}

        <img src={!open ? downArrowSVG : upArrowSVG} width={20} className={css.fieldSelectIcon} />
      </div>

      {/* Dropdown Menu */}
      {open && (
        <ul
          className={classNames(css.fieldDropdown, {
            [css.fieldDropdownUp]: position === 'top',
          })}
          ref={dropdownRef}
        >
          {options.map(({ value, label }) => {
            return (
              <li
                key={value}
                className={input.value.includes(value) ? css.selectedOption : ''}
                onClick={() => addOption(value)}
              >
                {label}
              </li>
            );
          })}
        </ul>
      )}

      <ValidationError fieldMeta={meta} />
    </div>
  );
};

MultipleFieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  selectClassName: null,
  id: null,
  label: null,
  children: null,
  labelClassName: null,
};

const { string, object, node } = PropTypes;

MultipleFieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  selectClassName: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,
  labelClassName: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,

  children: node,
};

const MultipleFieldSelect = props => {
  const { children } = props;
  const options = React.Children.toArray(children).map(child => ({
    label: child.props.label,
    value: child.props.value,
  }));
  return <Field component={MultipleFieldSelectComponent} {...props} options={options} />;
};

export default MultipleFieldSelect;
