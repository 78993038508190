/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  CreateCompanyPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for   ng and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'registerType',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'candidate',
        label: 'Candidate',
      },
      {
        option: 'company',
        label: 'Company',
      },
    ],
    filterConfig: {
      indexForSearch: false,
      filterType: 'SelectSingleFilter',
      label: " I'm a...",
      group: 'primary',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: " I'm a...",
      isDetail: false,
    },
    saveConfig: {
      label: " I'm a...",
      placeholderMessage: '',
      isRequired: true,
      requiredMessage: 'registerType is required.',
      saveType: 'enum',
    },
  },

  {
    key: 'industry',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'architecture',
        label: 'Architecture',
      },
      {
        option: 'interior-design',
        label: 'Interior Design',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Industry',
      group: 'primary',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'Industry',
      isDetail: true,
    },
    saveConfig: {
      label: 'Industry',
      placeholderMessage: 'Select your industry',
      isRequired: true,
      requiredMessage: 'Industry is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'primarySpecialty',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'single-family', label: 'Single-family residential' },
      { option: 'multi-family', label: 'Multi-family residential' },
      { option: 'hospitality', label: 'Hospitality' },
      { option: 'retail', label: 'Retail' },
      { option: 'healthcare', label: 'Healthcare' },
      { option: 'large-scale', label: 'Large-scale' },
      { option: 'civic', label: 'Civic' },
      { option: 'industrial', label: 'Industrial' },
      { option: 'landscape', label: 'Landscape' },
      { option: 'commercial', label: 'Commercial' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Specialty',
      group: 'primary',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'Primary specialty',
      isDetail: true,
    },
    saveConfig: {
      label: 'Primary specialty',
      placeholderMessage: 'Primary specialty',
      isRequired: true,
      requiredMessage: 'Primary specialty is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'secondarySpecialty',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'single-family', label: 'Single-family residential' },
      { option: 'multi-family', label: 'Multi-family residential' },
      { option: 'hospitality', label: 'Hospitality' },
      { option: 'retail', label: 'Retail' },
      { option: 'healthcare', label: 'Healthcare' },
      { option: 'large-scale', label: 'Large-scale' },
      { option: 'civic', label: 'Civic' },
      { option: 'industrial', label: 'Industrial' },
      { option: 'landscape', label: 'Landscape' },
      { option: 'commercial', label: 'Commercial' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Secondary specialty',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'Secondary specialty',
      isDetail: true,
    },
    saveConfig: {
      label: 'Secondary specialty (optional)',
      placeholderMessage: 'Secondary specialty',
      isRequired: false,
      requiredMessage: 'Secondary specialty is required.',
      saveType: 'multi-enum',
    },
  },
  {
    key: 'availability',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      {
        option: 'actively-looking',
        label: 'Actively looking',
      },
      {
        option: 'passively-looking',
        label: 'Passively looking',
      },
      {
        option: 'not-looking',
        label: 'Not looking',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Availability',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'Availability',
      isDetail: true,
    },
    saveConfig: {
      label: 'Availability',
      placeholderMessage: 'Availability',
      isRequired: true,
      requiredMessage: 'Availability is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'workplacePreference',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'on-site',
        label: 'On-site',
      },
      {
        option: 'fully-remote',
        label: 'Fully remote',
      },
      {
        option: 'hybrid',
        label: 'Hybrid',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Workplace preference',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Workplace preference',
      isDetail: true,
    },
    saveConfig: {
      label: 'Workplace preference',
      placeholderMessage: 'Workplace preference',
      isRequired: true,
      requiredMessage: 'Workplace preference is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'openTo',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: 'full-time',
        label: 'Full-time',
      },
      {
        option: 'part-time',
        label: 'Part-time',
      },
      {
        option: 'both',
        label: 'Both',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Open To',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'Open to',
      isDetail: true,
    },
    saveConfig: {
      label: 'Open to',
      placeholderMessage: 'Open to',
      isRequired: true,
      requiredMessage: 'Open to is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'experience',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      {
        option: '1',
        label: '1 year',
      },
      { option: '2', label: '2 years' },
      { option: '3', label: '3 years' },
      { option: '4', label: '4 years' },
      { option: '5', label: '5 years' },
      { option: '6', label: '6 years' },
      { option: '7', label: '7 years' },
      { option: '8', label: '8 years' },
      { option: '9', label: '9 years' },
      { option: '10', label: '10 years' },
      { option: '11 -15 ', label: '11 to 15 years' },
      { option: '15-20', label: '15 to 20 years' },
      { option: '20', label: '20+ years' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Years of post-grad design experience',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Years of post-grad design experience',
      isDetail: true,
    },
    saveConfig: {
      label: 'Years of post-grad design experience',
      placeholderMessage: 'Years of post-grad design experience',
      isRequired: true,
      requiredMessage: 'Post-grad design experience is required.',
      // min: 1,
      // max: 30,
      // handle: [1, 10],
      saveType: 'enum',
    },
  },
  {
    key: 'graduationYear',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: '2029', label: '2029' },
      { option: '2028', label: '2028' },
      { option: '2027', label: '2027' },
      { option: '2026', label: '2026' },
      { option: '2025', label: '2025' },
      { option: '2024', label: '2024' },
      { option: '2023', label: '2023' },
      { option: '2022', label: '2022' },
      { option: '2021', label: '2021' },
      { option: '2020', label: '2020' },
      { option: '2019', label: '2019' },
      { option: '2018', label: '2018' },
      { option: '2017', label: '2017' },
      { option: '2016', label: '2016' },
      { option: '2015', label: '2015' },
      { option: '2014', label: '2014' },
      { option: '2013', label: '2013' },
      { option: '2012', label: '2012' },
      { option: '2011', label: '2011' },
      { option: '2010', label: '2010' },
      { option: '2009', label: '2009' },
      { option: '2008', label: '2008' },
      { option: '2007', label: '2007' },
      { option: '2006', label: '2006' },
      { option: '2005', label: '2005' },
      { option: '2004', label: '2004' },
      { option: '2003', label: '2003' },
      { option: '2002', label: '2002' },
      { option: '2001', label: '2001' },
      { option: '2000', label: '2000' },
      { option: '1999', label: '1999' },
      { option: '1998', label: '1998' },
      { option: '1997', label: '1997' },
      { option: '1996', label: '1996' },
      { option: '1995', label: '1995' },
      { option: '1994', label: '1994' },
    ],

    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Graduation year',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Graduation year',
      isDetail: true,
    },
    saveConfig: {
      label: 'Graduation year',
      placeholderMessage: 'Graduation year',
      isRequired: true,
      requiredMessage: 'Graduation year is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'salaryRequirements',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      {
        option: '50-60',
        label: '$50,000-$60,000',
      },
      {
        option: '60-70',
        label: '$60,000-$70,000',
      },
      {
        option: '70-80',
        label: '$70,000-$80,000',
      },
      {
        option: '80-90',
        label: '$80,000-$90,000',
      },
      {
        option: '90-100',
        label: '$90,000-$100,000',
      },
      {
        option: '100-110',
        label: '$100,000-$110,000',
      },
      {
        option: '110-120',
        label: '$110,000-$120,000',
      },
      {
        option: '120-130',
        label: '$120,000-$130,000',
      },
      {
        option: '130-140',
        label: '$130,000-$140,000',
      },
      {
        option: '140-150',
        label: '$140,000-$150,000',
      },
      {
        option: '150',
        label: '$150,000-$250,000',
      },
      {
        option: '250-500',
        label: '$250,000-$500,000',
      },
      {
        option: '500',
        label: '$500,000+',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Salary',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Desired salary',
      isDetail: true,
    },
    saveConfig: {
      label: 'Desired salary (optional)',
      placeholderMessage: 'Salary requirements',
      isRequired: true,
      requiredMessage: 'Salary requirements is required.',
      saveType: 'multi-enum',
    },
  },
  {
    key: 'companySize',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      {
        option: '110',
        label: '1-10 employees',
      },
      {
        option: '1020',
        label: '10-20 employees',
      },
      {
        option: '2030',
        label: '20-30 employees',
      },
      {
        option: '3040',
        label: '30-40 employees',
      },
      {
        option: '4050',
        label: '40-50 employees',
      },
      {
        option: '50',
        label: '50+ employees',
      },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Company size',
      group: 'primary',
      showFor: ['company'],
    },
    showConfig: {
      label: 'Team size',
      isDetail: true,
    },
    saveConfig: {
      label: 'Company size',
      placeholderMessage: 'Company size (Number of Employees)',
      isRequired: true,
      requiredMessage: 'Company size is required.',
      saveType: 'enum',
    },
  },
  // {
  //   key: 'interests',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'single-family', label: 'Single-family' },
  //     { option: 'multi-family', label: 'Multi-family' },
  //     { option: 'hospitality', label: 'Hospitality' },
  //     { option: 'retail', label: 'Retail' },
  //     { option: 'healthcare', label: 'Healthcare' },
  //     { option: 'large-scale', label: 'Large-scale' },
  //     { option: 'civic', label: 'Civic' },
  //     { option: 'industrial', label: 'Industrial' },
  //     { option: 'landscape', label: 'Landscape' },
  //     { option: 'commercial', label: 'Commercial' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Interests',
  //     group: 'primary',
  //     searchMode: 'has_any',
  //     showFor: ['talent'],
  //   },
  //   showConfig: {
  //     label: 'Interests',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Interests (optional)',
  //     placeholderMessage: 'Interests',
  //     isRequired: true,
  //     requiredMessage: 'Interest is required.',
  //     saveType: 'multi-enum',
  //   },
  // },
  {
    key: 'style',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'bohemian', label: 'Bohemian' },
      { option: 'bright', label: 'Bright' },
      { option: 'classic', label: 'Classic' },
      { option: 'coastal', label: 'Coastal' },
      { option: 'colorful', label: 'Colorful' },
      { option: 'cottage', label: 'Cottage' },
      { option: 'contemporary', label: 'Contemporary' },
      { option: 'country', label: 'Country' },
      { option: 'eclectic', label: 'Eclectic' },
      { option: 'farmhouse', label: 'Farmhouse' },
      { option: 'glamorous', label: 'Glamorous' },
      { option: 'industrial', label: 'Industrial' },
      { option: 'mid-century', label: 'Mid-Century' },
      { option: 'minimal', label: 'Minimal' },
      { option: 'modern', label: 'Modern' },
      { option: 'rustic', label: 'Rustic' },
      { option: 'scandinavian', label: 'Scandinavian' },
      { option: 'southern', label: 'Southern' },
      { option: 'transitional', label: 'Transitional' },
      { option: 'traditional', label: 'Traditional' },
      { option: 'preppy', label: 'Preppy' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Style',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'Style',
      isDetail: true,
    },
    saveConfig: {
      label: 'Style',
      placeholderMessage: 'Style',
      isRequired: false,
      // requiredMessage: 'Style is required.',
      saveType: 'multi-enum',
    },
  },

  {
    key: 'wfhPolicy',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: 'on-site', label: 'On-site' },
      { option: 'remote', label: 'Remote' },
      { option: 'hybrid', label: 'Hybrid' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'WFH Policy',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['company'],
    },
    showConfig: {
      label: 'WFH Policy',
      isDetail: true,
    },
    saveConfig: {
      label: 'WFH Policy',
      placeholderMessage: 'What kind of work enviornment do you offer?',
      isRequired: true,
      requiredMessage: 'WFH Policy is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'ad100',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'ad100', label: 'AD100' },
      { option: 'elle-decor', label: 'Elle Décor A-list' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'AD100 + Elle Décor',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['company'],
    },
    showConfig: {
      label: 'AD100 + Elle Décor',
      isDetail: true,
    },
    saveConfig: {
      label: 'AD100 + Elle Décor (Optional)',
      placeholderMessage: 'AD100 + Elle Décor',
      isRequired: false,
      requiredMessage: 'AD100 + Elle Décor is required.',
      saveType: 'multi-enum',
    },
  },
  {
    key: 'program',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'autoCAD', label: 'AutoCAD' },
      { option: 'hand-sketching', label: 'Hand Sketching' },
      { option: 'revit', label: 'Revit' },
      { option: 'ms-office', label: 'MS Office' },
      { option: 'furniture-design', label: 'Furniture Design' },
      { option: 'space-planning', label: 'Space Planning' },
      { option: 'vectorworks', label: 'Vectorworks' },
      { option: 'studio-designer', label: 'Studio Designer' },
      { option: 'sketchup', label: 'Sketchup' },
      { option: 'photoshop', label: 'Photoshop' },
      { option: 'custom-millwork', label: 'Custom Millwork' },
      { option: 'indesign', label: 'InDesign' },
      { option: 'rhino', label: 'Rhino' },
      { option: 'rendering', label: 'Rendering' },
      { option: 'enscape', label: 'Enscape' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Skills',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Skills',
      isDetail: true,
    },
    saveConfig: {
      label: 'Skills',
      placeholderMessage: 'Skills',
      isRequired: true,
      requiredMessage: 'Skills are required.',
      saveType: 'multi-enum',
    },
  },
  {
    key: 'newsletter',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [{ option: 'newsletter-checked', label: 'Read the tnc' }],
    filterConfig: {
      indexForSearch: false,
      filterType: 'SelectMultipleFilter',
      label: 'NewsLetter',
      group: 'primary',
      showFor: ['company', 'talent'],
    },
    showConfig: {
      label: 'NewsLetter',
      isDetail: false,
    },
    saveConfig: {
      label: 'NewsLetter',
      placeholderMessage: 'NewsLetter',
      isRequired: false,
      requiredMessage: 'NewsLetter is required.',
      saveType: 'enum',
    },
  },
  {
    key: 'expertise',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'technical-designer', label: 'Technical Designer' },
      { option: 'project-manager', label: 'Project Manager' },
      { option: 'construction-specialist', label: 'Construction Specialist' },
      { option: 'interior-architect', label: 'Interior Architect' },
      { option: 'FF&E-tastemaker', label: 'FF&E Tastemaker' },
      { option: 'rendering', label: 'Rendering' },
      { option: 'procurement', label: 'Procurement' },
      { option: 'other', label: 'other' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Expertise',
      group: 'primary',
      searchMode: 'has_any',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Expertise',
      isDetail: true,
    },
    saveConfig: {
      label: 'Expertise (Optional)',
      placeholderMessage: 'Expertise',
      isRequired: false,
      requiredMessage: 'Expertise is required.',
      saveType: 'multi-enum',
    },
  },
  {
    key: 'licensesAndCertification',
    scope: 'public',
    schemaType: 'multi-enum',
    enumOptions: [
      { option: 'aia', label: 'AIA' },
      { option: 'registered-architect', label: 'Registered Architect' },
      { option: 'ncarb', label: 'NCARB' },
      { option: 'ncidq', label: 'NCIDQ' },
      { option: 'leed', label: 'LEED' },
      { option: 'iida', label: 'IIDA' },
    ],
    filterConfig: {
      indexForSearch: false,
      filterType: 'SelectMultipleFilter',
      label: 'Licenses and certification',
      group: 'primary',
      showFor: ['talent'],
    },
    showConfig: {
      label: 'Licenses and certification',
      isDetail: true,
    },
    saveConfig: {
      label: 'Licenses and certification (Optional)',
      placeholderMessage: 'Licenses and certification',
      isRequired: false,
      requiredMessage: 'Licenses and certification is required.',
      saveType: 'multi-enum',
    },
  },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: With first iteration of hosted configs, we are unlikely to support
//         multiple listing types, even though this template has some
//         rudimentary support for it.
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:     Unique string. This will be saved to listing's public data on
 *                    EditListingWizard.
 * - label            Label for the listing type. Used as microcopy for options to select
 *                    listing type in EditListingWizard.
 * - transactionType  Set of configurations how this listing type will behave when transaction is
 *                    created.
 *   - process          Transaction process.
 *                      The process must match one of the processes that this client app can handle
 *                      (check src/util/transaction.js) and the process must also exists in correct
 *                      marketplace environment.
 *   - alias            Valid alias for the aforementioned process. This will be saved to listing's
 *                      public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType         Unit type is mainly used as pricing unit. This will be saved to
 *                      transaction's protected data.
 *                      Recommendation: don't use same unit types in completely different processes
 *                      ('item' sold should not be priced the same as 'item' booked).
 * - stockType        This is relevant only to listings with product-selling listing type.
 *                    If set to 'oneItem', stock management is not showed and the listing is
 *                    considered unique (stock = 1).
 *                    Possible values: 'oneItem' and 'multipleItems'.
 *                    Default: 'multipleItems'.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

// Since our listing represents a profile, we define the user types that can be used
// to check if a specific user or listing has a certain type.
export const userTypes = {
  company: 'company',
  talent: 'talent',
};
