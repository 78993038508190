import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { func, object, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { compose } from 'redux';

// Import util modules
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';

// Import shared components
import {
  FieldFileUpload,
  FieldPhoneNumberInput,
  FieldRadioButtonGroup,
  FieldTextInput,
  Form,
  SecondaryButton,
} from '../../../components';
// Import modules from this directory
import { OnChange } from 'react-final-form-listeners';
import vectorSVG from '../../../assets/Vector.svg';
import { useConfiguration } from '../../../context/configurationContext';
import css from './SignupForm.module.css';

// Show various error messages
const ErrorMessage = props => {
  const { fetchErrors } = props;
  const { updateListingError, submitFormError } = fetchErrors || {};
  const errorMessage = updateListingError ? (
    <FormattedMessage id="CreateTalentWorkForm.updateFailed" />
  ) : null;
  const submitFormErrorMessage = submitFormError ? (
    <FormattedMessage id="CreateTalentWorkForm.submitFailed" />
  ) : null;

  if (errorMessage) {
    return <p className={css.error}>{errorMessage}</p>;
  } else if (submitFormError) {
    return <p className={css.error}>{submitFormErrorMessage}</p>;
  }
  return null;
};

const SignUpFormComponent = props => {
  const { validate } = props;
  const fileRef = useRef(null);
  const [option, setOption] = useState('');
  const [fileName, setFileName] = useState('');

  // UseEffect to trigger file input click when option changes to 'upload'
  useEffect(() => {
    if (option === 'upload' && fileRef.current) {
      fileRef.current.click();
    }
  }, [option]);

  const handleOptionChange = optionchange => {
    setOption(optionchange);
    // if (optionchange === 'upload' && fileRef.current) {
    //   fileRef.current.click(); // Trigger the file input click immediately
    // }
  };
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      validate={validate}
      // validate={() => {
      //   const errors = {};
      //   if (props.isEmailTaken) {
      //     const emailTakenMessage = props.intl.formatMessage({
      //       id: 'CreateCompanyBasicsForm.emailTaken',
      //     });
      //     errors.email = emailTakenMessage;
      //   }
      //   return errors;
      // }}
      render={formRenderProps => {
        const {
          className,
          formId,
          handleSubmit,
          onEmailChangeWithDebounce,
          isEmailTaken,
          intl,
          values,
          saveActionMsg,
          fetchErrors,
          form,
          initialValues,
        } = formRenderProps;

        // useEffect(() => {
        //   if (isEmailTaken === true) {
        //     const { email } = initialValues || {};
        //     // form.change('email', email);
        //     // You might also want to set it as touched to trigger validation
        //     form.blur('email'); // Ensure it is considered as touched
        //     form.focus('email'); // Optionally focus back for better UX
        //   }
        // }, [isEmailTaken]);

        const config = useConfiguration();
        const { listingFields } = config?.listing || {};
        const listingFieldsRegisterType = listingFields.find(l => l.key === 'registerType');
        const registerOptions = listingFieldsRegisterType
          ? listingFieldsRegisterType.enumOptions
          : [];

        const firstNameRequiredMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        const lastNameRequiredMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const emailRequiredMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'CreateTalentBasicsForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const classes = classNames(css.root, className);
        const { registerType, email, firstName, lastName, name, option, file } = values;
        const submitDisabled =
          registerType === 'candidate'
            ? !email || !firstName || !lastName || isEmailTaken
            : !email || !name || isEmailTaken;

        const NAME_MAX_LENGTH = 60;
        const nameRequiredMessage = intl.formatMessage({
          id: 'CreateCompanyBasicsForm.nameRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'CreateCompanyBasicsForm.maxLength' },
          {
            maxLength: NAME_MAX_LENGTH,
          }
        );
        const maxLength60Message = validators.maxLength(maxLengthMessage, NAME_MAX_LENGTH);
        const firstNameLabel = intl.formatMessage({
          id: 'LandingPage.SignupForm.firstName',
        });
        const lastNameLabel = intl.formatMessage({
          id: 'LandingPage.SignupForm.lastName',
        });

        const selectoption = intl.formatMessage({
          id: 'SignupForm.selectOption',
        });

        const optionUpload = intl.formatMessage({ id: 'SignupForm.upload' });
        const optionLinkedIn = intl.formatMessage({ id: 'SignupForm.linkedIn' });

        const linkedInLabel = intl.formatMessage({
          id: 'SignupForm.linkedInLabel',
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <ErrorMessage fetchErrors={fetchErrors} />

            <div className={css.fieldsWrapper}>
              <FieldRadioButtonGroup
                id={`${formId}.${listingFieldsRegisterType.key}`}
                name={listingFieldsRegisterType.key}
                label={listingFieldsRegisterType.saveConfig.label}
                options={registerOptions.map(o => ({
                  key: o.option,
                  label: o.label,
                }))}
                customListClassName={css.radioFlex}
                customLabelInput={css.customLabelInput}
                customItem={css.customItem}
              />

              {registerType === 'candidate' ? (
                <div className={classNames(css.fieldFlex)}>
                  <FieldTextInput
                    id={`${formId}.firstName`}
                    label={firstNameLabel}
                    name="firstName"
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateTalentBasicsForm.firstNamePlaceholder',
                    })}
                    validate={file ? firstNameRequired : null}
                    inputClassName={css.inputStyle}
                    customLabelInput={css.customLabelInput}
                  />
                  <FieldTextInput
                    id={`${formId}.lastName`}
                    label={lastNameLabel}
                    name="lastName"
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateTalentBasicsForm.lastNamePlaceholder',
                    })}
                    validate={file ? lastNameRequired : null}
                    inputClassName={css.inputStyle}
                    customLabelInput={css.customLabelInput}
                  />
                </div>
              ) : (
                <div>
                  <FieldTextInput
                    id={`${formId}.name`}
                    name="name"
                    label={intl.formatMessage({
                      id: 'LandingPage.SignupForm.companyName',
                    })}
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateCompanyBasicsForm.namePlaceholder',
                    })}
                    maxLength={NAME_MAX_LENGTH}
                    validate={
                      file
                        ? validators.composeValidators(
                          validators.required(nameRequiredMessage),
                          maxLength60Message
                        )
                        : null
                    }
                    inputClassName={css.inputStyle}
                    customLabelInput={css.customLabelInput}
                  />
                </div>
              )}

              <div className={css.fieldFlex}>
                <FieldTextInput
                  id={`${formId}.email`}
                  label={intl.formatMessage({ id: 'LandingPage.SignupForm.email' })}
                  name="email"
                  className={css.field}
                  type="email"
                  placeholder={intl.formatMessage({
                    id: 'CreateTalentBasicsForm.emailPlaceholder',
                  })}
                  validate={file ? validators.composeValidators(emailRequired, emailValid) : null}
                  inputClassName={css.inputStyle}
                  customLabelInput={css.customLabelInput}
                />
                <OnChange name="email">
                  {(value, previous) => onEmailChangeWithDebounce({ email: value })}
                </OnChange>
              </div>
              {registerType === 'candidate' ? (
                <div>
                  <FieldPhoneNumberInput
                    id={`${formId}.phone`}
                    label={intl.formatMessage({ id: 'LandingPage.SignupForm.phoneNumber' })}
                    name="phone"
                    className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'CreateTalentBasicsForm.phonePlaceholder',
                    })}
                    inputClassName={css.inputStyle}
                    customLabelInput={css.customLabelInput}
                  />
                </div>
              ) : null}
            </div>
            {/* change to candidate when we want to implement this back */}
            {registerType === 'candidate2' ? (
              <div>
                {' '}
                <div className={css.autofillin}>
                  <div className={css.autofillinFlex}>
                    <img src={vectorSVG} />
                    <FormattedMessage id="SignupForm.autofillin" />
                  </div>
                  <div>
                    <FormattedMessage id="SignupForm.autofillinDescription" />
                  </div>
                  <div>
                    {/* <FieldSelect
                  name="option"
                  id={formId ? `${formId}.option` : 'option'}
                  className={css.fieldSelect}
                  selectClassName={css.select}
                  onChange={handleOptionChange}
                >
                  <option value="" disabled>
                    {selectoption}
                  </option>
                  <option value="upload">{optionUpload}</option>
                  <option value="linkedin">{optionLinkedIn}</option>
                </FieldSelect> */}

                    {/* {option === 'upload' ? (
                  <div>
                    <FieldFileUpload
                      name="file"
                      // accept="image/*"
                      // maxSize={5 * 1024 * 1024} // 5 MB limit
                      // customErrorText="File is too large. Please upload a smaller file."
                      inputRef={fileRef}
                      type="file"
                    />
                  </div>
                ) : null} */}
                    <FieldFileUpload
                      name="file"
                      // accept="image/*"
                      // maxSize={5 * 1024 * 1024} // 5 MB limit
                      // customErrorText="File is too large. Please upload a smaller file."
                      inputRef={fileRef}
                      type="file"
                      id="file"
                    />
                  </div>
                </div>
                {/* {option === 'linkedin' ? (
                <div className={css.paddingTop}>
                  <FieldTextInput
                    id={`${formId}.linkedinURL`}
                    label={linkedInLabel}
                    name="linkedinURL"
                    // className={css.field}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.linkedInPlaceholder',
                    })}
                    validate={firstNameRequired}
                    inputClassName={css.inputStyle}
                    customLabelInput={css.customLabelInput}
                  />
                </div>
              ) : null} */}
                {/* <div className={css.divider}>OR</div> */}
              </div>
            ) : null}

            <SecondaryButton
              className={css.landingpageSubmitButton}
              type="submit"
              disabled={submitDisabled}
            >
              {saveActionMsg}
            </SecondaryButton>
          </Form>
        );
      }}
    />
  );
};

SignUpFormComponent.defaultProps = {
  className: null,
  formId: 'SignupForm',
  fetchErrors: null,
};

SignUpFormComponent.propTypes = {
  className: string,
  formId: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  // onRedirectToPreviousTab: func.isRequired,
  saveActionMsg: string.isRequired,
  fetchErrors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
  }).isRequired,
  validate: func,
};

export default compose(injectIntl)(SignUpFormComponent);
