import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { debounce } from 'lodash';
import { bool, number, object, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';
import {
  ChangeableImageFromFile,
  Footer,
  Form,
  H2,
  IconBack,
  IconSpinner,
  LayoutSingleColumn,
  NamedRedirect,
  Page,
} from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { emailTaken } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { ensureCurrentUser } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/uiHelpers';
import { generateRandomUUID } from '../../util/uuid';
import * as validators from '../../util/validators';
import AsideNav, { BASICS, PORTFOLIO, SPECIFICS, STYLES, WORKS } from '../AboutPage/AsideNav';
import {
  requestCreateTalent,
  updateorcreateMailChimpMember,
} from '../CreateTalentPage/CreateTalentPage.duck';
import IconPlus from '../SearchPage/IconPlus/IconPlus';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import TalentSignUpBasicForm from './TalentSignUpBasicForm';
import css from './TalentSignUpPage.module.css';
import TalentSignUpPortfolioForm from './TalentSignUpPortfolioForm';
import TalentSignUpSpecificsForm from './TalentSignUpSpecificsForm';
import TalentSignUpStyleForm from './TalentSignUpStyleForm';
import TalentSignUpWorkForm from './TalentSignUpWorkForm';

const MAX_MOBILE_SCREEN_WIDTH = 769;
const TAB_LISTING_FIELD_IDS = [
  'industry',
  'primarySpecialty',
  'secondarySpecialty',
  'expertise',
  'graduationYear',
  'experience',
  'salaryRequirements',
  'licensesAndCertification',
  'program',
  'style',
  'availability',
  'workplacePreference',
  'openTo',
  'newsletter',
];

const MIN_IMAGES = 1;
const DEBOUNCE_WAIT_TIME = 200;
const PORTAL_ROOT_CONTAINER_ID = 'portal-root';

const getSectionComponents = componentProps => [
  <TalentSignUpBasicForm key={BASICS} sectionId={BASICS} {...componentProps} />,
  <TalentSignUpWorkForm key={WORKS} sectionId={WORKS} {...componentProps} />,
  <TalentSignUpSpecificsForm key={SPECIFICS} sectionId={SPECIFICS} {...componentProps} />,
  <TalentSignUpStyleForm key={STYLES} sectionId={STYLES} {...componentProps} />,
  <TalentSignUpPortfolioForm key={PORTFOLIO} sectionId={PORTFOLIO} {...componentProps} />,
];

export const TalentFormContent = props => {
  const { title, children, id } = props;
  return (
    <div id={id} className={css.formContent}>
      <div className={css.formContentTitle}>{title}</div>
      {children}
    </div>
  );
};

const TalentSignUpPageComponent = props => {
  const {
    intl,
    viewport,
    scrollingDisabled,
    initialValues,
    isEmailTaken,
    talentProfileData,
    onCreateTalent,
    currentUser,
    page,
    isAuthenticated,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;

  if (isAuthenticated && currentUserLoaded && !page.updateInProgress) {
    return <NamedRedirect name="ProfileCompletePage" params={{ type: 'talent' }} />;
  }

  const uploadedFileFromInitialValues = initialValues?.profileImage
    ? initialValues?.profileImage
    : null;

  const uploadedFilesFromInitialValues = initialValues?.images ? initialValues?.images : [];
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [publishInProgress, setPublishInProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    if (talentProfileData && talentProfileData.email) {
      dispatch(emailTaken({ email: talentProfileData.email }));
    }
  }, []);

  const handleSubmit = values => {
    const { email } = values;

    if (validators.emailFormatValidWithoutForm(email)) {
      dispatch(emailTaken(values));
    }
  };

  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  const [uploadedFile, setUploadedFile] = useState(uploadedFileFromInitialValues);
  const [files, setFiles] = useState(uploadedFilesFromInitialValues);

  const config = useConfiguration();
  const listingConfig = config.listing;
  const listingFields = listingConfig.listingFields.filter(l =>
    TAB_LISTING_FIELD_IDS.includes(l.key)
  );

  const industry = listingFields.find(l => l.key === 'industry');
  const industryOptions = industry ? industry.enumOptions : [];

  const expertise = listingFields.find(l => l.key === 'expertise');
  const expertiseOptions = expertise ? expertise.enumOptions : [];

  const primarySpecialty = listingFields.find(l => l.key === 'primarySpecialty');
  const primarySpecialtyOptions = primarySpecialty ? primarySpecialty.enumOptions : [];

  const secondarySpecialty = listingFields.find(l => l.key === 'secondarySpecialty');
  const secondarySpecialtyOptions = secondarySpecialty ? secondarySpecialty.enumOptions : [];

  const graduationYear = listingFields.find(l => l.key === 'graduationYear');
  const graduationYearOptions = graduationYear ? graduationYear.enumOptions : [];

  const yearsOfExperience = listingFields.find(l => l.key === 'experience');
  const yearsOfExperienceOptions = yearsOfExperience ? yearsOfExperience.enumOptions : [];

  const salaryRequirements = listingFields.find(l => l.key === 'salaryRequirements');
  const salaryRequirementsOptions = salaryRequirements ? salaryRequirements.enumOptions : [];

  const licensesAndCertification = listingFields.find(l => l.key === 'licensesAndCertification');
  const licensesAndCertificationOptions = licensesAndCertification
    ? licensesAndCertification.enumOptions
    : [];

  const program = listingFields.find(l => l.key === 'program');
  const programOptions = program ? program.enumOptions : [];

  const style = listingFields.find(l => l.key === 'style');
  const styleOptions = style ? style.enumOptions : [];

  const availability = listingFields.find(l => l.key === 'availability');
  const availabilityOptions = availability ? availability.enumOptions : [];

  const workplacePreference = listingFields.find(l => l.key === 'workplacePreference');
  const workplacePreferenceOptions = workplacePreference ? workplacePreference.enumOptions : [];

  const openTo = listingFields.find(l => l.key === 'openTo');
  const openToOptions = openTo ? openTo.enumOptions : [];

  const newsletter = listingFields.find(l => l.key === 'newsletter');
  const newsletterOptions = newsletter ? newsletter.enumOptions : [];

  const marketplaceName = config.marketplaceName;
  const schemaTitle = 'Talent Page ';
  const schemaDescription = 'Talent SignUp Flow';
  const NAV = [BASICS, WORKS, SPECIFICS, STYLES, PORTFOLIO];
  const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;
  const { profileData } = page || {};
  const { s3URL } = profileData || {};

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbarClassName={css.topbar}
        topbar={<TopbarContainer />}
        footer={<Footer />}
      >
        {!loadingPage ? (
          <div className={css.content}>
            <div className={css.asideContent}>
              <AsideNav intl={intl} isMobileLayout={isMobileLayout} NAV={NAV} />
            </div>
            <div className={css.backContent}>
              <div className={css.iconBack} onClick={() => history.goBack()}>
                <IconBack />
                {/* <div>
                <FormattedMessage id="TalentSignUpPage.topic" />
              </div> */}
              </div>
            </div>
            <FinalForm
              className={css.sections}
              intl={intl}
              onEmailChangeWithDebounce={handleChangeWithDebounce}
              saveActionMsg="SUBMIT APPLICATION"
              initialValues={talentProfileData}
              onSubmit={values => {
                try {
                  setSubmitInProgress(true);
                  const {
                    id,
                    firstName,
                    lastName,
                    email,
                    phone,
                    birthday,
                    industry,
                    primarySpecialty,
                    secondarySpecialty,
                    interests,
                    expertise,
                    location,
                    title,
                    company,
                    university,
                    degree,
                    graduationYear,
                    favoriteDesignFirm,
                    experience,
                    salaryRequirements,
                    licensesAndCertification,
                    program,
                    style,
                    customStyles,
                    availability,
                    workplacePreference,
                    openTo,
                    website,
                    linkedin,
                    instagram,
                    profileImage,
                    images,
                    newsletter,
                    password,
                  } = values;

                  const { selectedPlace } = location;
                  const geolocation = selectedPlace?.origin;
                  const googleMapsUrl = selectedPlace?.url;
                  if (!geolocation || !googleMapsUrl) {
                    setSubmitError(true);
                    setSubmitInProgress(false);
                    alert('Location not specific, please fill in again!');
                  } else {
                    const splitAddr1 = selectedPlace?.address.split(',');
                    const address = splitAddr1
                      ? {
                          addressLine1: splitAddr1[0],
                          city: splitAddr1[1],
                          zip: splitAddr1[2],
                        }
                      : {};
                    let updateValues = {
                      id: id ? id : generateRandomUUID(),
                      firstName,
                      lastName,
                      email,
                      phone,
                      birthday,
                      address,
                      geolocation,
                      industry,
                      primarySpecialty,
                      secondarySpecialty,
                      interests,
                      expertise,
                      location,
                      googleMapsUrl,
                      title,
                      company,
                      university,
                      degree,
                      graduationYear: [graduationYear],
                      favoriteDesignFirm,
                      experience: [experience],
                      salaryRequirements,
                      licensesAndCertification,
                      program,
                      style,
                      customStyles,
                      availability: [availability],
                      workplacePreference,
                      openTo,
                      website,
                      linkedin,
                      instagram,
                      profileImage,
                      images,
                      password,
                    };
                    if (s3URL) updateValues.s3URL = s3URL;

                    setSubmitInProgress(false);
                    const objectHaveValues = Object.fromEntries(
                      Object.entries(updateValues).filter(([_, value]) => value !== undefined)
                    );

                    if (newsletter && Array.isArray(newsletter) && newsletter.length > 0) {
                      const mailchimpMember = {
                        ...values,
                        step: 5,
                        registertype: 'Candidates',
                      };

                      dispatch(updateorcreateMailChimpMember(mailchimpMember));
                    } else {
                      // step 6 is stand for unsubsribe
                      const mailchimpMember = {
                        ...values,
                        step: 6,
                        registertype: 'Candidates',
                      };

                      dispatch(updateorcreateMailChimpMember(mailchimpMember));
                    }
                    setPublishInProgress(true);
                    onCreateTalent(objectHaveValues);
                  }
                } catch (e) {
                  setSubmitError(true);
                  setSubmitInProgress(false);
                }
              }}
              mutators={{ ...arrayMutators }}
              validate={() => {
                const errors = {};
                if (isEmailTaken) {
                  const emailTakenMessage = intl.formatMessage({
                    id: 'CreateCompanyBasicsForm.emailTaken',
                  });
                  errors.email = emailTakenMessage;
                }
                return errors;
              }}
              render={formRenderProps => {
                const {
                  className,
                  disabled,
                  ready,
                  formId,
                  handleSubmit,
                  onEmailChangeWithDebounce,
                  isEmailTaken,
                  intl,
                  invalid,
                  pristine,
                  values,
                  saveActionMsg,
                  updated,
                  updateInProgress,
                  fetchErrors,
                  initialValues,
                  form,
                } = formRenderProps;

                useEffect(() => {
                  if (isEmailTaken) {
                    const { email } = initialValues || {};
                    // You might also want to set it as touched to trigger validation
                    form.blur('email'); // Ensure it is considered as touched
                    form.focus('email'); // Optionally focus back for better UX
                  }
                }, [isEmailTaken]);

                const firstNameRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.firstNameRequired',
                });
                const firstNameRequired = validators.required(firstNameRequiredMessage);

                const lastNameRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.lastNameRequired',
                });
                const lastNameRequired = validators.required(lastNameRequiredMessage);

                const emailRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.emailRequired',
                });
                const emailRequired = validators.required(emailRequiredMessage);
                const emailInvalidMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.emailInvalid',
                });
                const emailValid = validators.emailFormatValid(emailInvalidMessage);

                const classes = classNames(css.root, className);
                const submitReady = (updated && pristine) || ready;
                setSubmitInProgress(updateInProgress);
                const submitDisabled = invalid || disabled || submitInProgress || isEmailTaken;

                const titleRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentSpecificsForm.titleRequired',
                });
                const titleRequired = validators.required(titleRequiredMessage);

                const companyRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentSpecificsForm.companyRequired',
                });
                const companyRequired = validators.required(companyRequiredMessage);

                const universityRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentSpecificsForm.universityRequired',
                });
                const universityRequired = validators.required(universityRequiredMessage);

                const imageId = uploadedFile && String(uploadedFile.lastModified);
                const imageFromFile = uploadedFile ? (
                  <ChangeableImageFromFile
                    id={imageId}
                    rootClassName={css.imageFromFile}
                    aspectWidth={1}
                    aspectHeight={1}
                    file={uploadedFile}
                  />
                ) : null;

                const chooseLogoLabel = imageFromFile ? (
                  <div className={css.imageFromFileWrapper}>
                    {imageFromFile}
                    <div className={css.imageFromFileLabelWrapper}>
                      <span className={css.imageFromFileLabel}>
                        <FormattedMessage id="CreateTalentPortfolioForm.changeLogoLabel" />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={css.uploadZone}>
                    <span className={css.uploadZoneLabel}>
                      <FormattedMessage id="CreateTalentPortfolioForm.chooseLogoLabel" />
                    </span>
                    <span className={css.uploadZoneIconWrapper}>
                      <IconPlus className={css.uploadZoneIcon} isOpen={false} />
                    </span>
                  </div>
                );

                const profileImageRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentPortfolioForm.profileImageRequired',
                });
                const profileImageRequired = validators.required(profileImageRequiredMessage);

                // password
                const passwordRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.passwordRequired',
                });
                const passwordMinLengthMessage = intl.formatMessage(
                  {
                    id: 'CreateTalentBasicsForm.passwordTooShort',
                  },
                  {
                    minLength: validators.PASSWORD_MIN_LENGTH,
                  }
                );
                const passwordMaxLengthMessage = intl.formatMessage(
                  {
                    id: 'CreateTalentBasicsForm.passwordTooLong',
                  },
                  {
                    maxLength: validators.PASSWORD_MAX_LENGTH,
                  }
                );
                const passwordMinLength = validators.minLength(
                  passwordMinLengthMessage,
                  validators.PASSWORD_MIN_LENGTH
                );
                const passwordMaxLength = validators.maxLength(
                  passwordMaxLengthMessage,
                  validators.PASSWORD_MAX_LENGTH
                );
                const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
                const passwordValidators = validators.composeValidators(
                  passwordRequired,
                  passwordMinLength,
                  passwordMaxLength
                );

                const confirmPasswordRequiredMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.confirmPasswordRequired',
                });
                const confirmPasswordInvalidMessage = intl.formatMessage({
                  id: 'CreateTalentBasicsForm.confirmPasswordInvalid',
                });
                const confirmPasswordRequired = validators.required(confirmPasswordRequiredMessage);
                const confirmPasswordInvalid = validators.passwordMatch(
                  confirmPasswordInvalidMessage,
                  values.password
                );
                const confirmPasswordValidators = validators.composeValidators(
                  confirmPasswordRequired,
                  confirmPasswordInvalid
                );

                const imagesFromValues = values.images;
                const hasEnoughImages = imagesFromValues?.length >= MIN_IMAGES;

                const sectionComponentProps = {
                  marketplaceName,
                  isMobileLayout,
                  formId,
                  firstNameRequired,
                  lastNameRequired,
                  emailRequired,
                  emailValid,
                  intl,
                  onEmailChangeWithDebounce,
                  values,
                  industry,
                  industryOptions,
                  primarySpecialty,
                  primarySpecialtyOptions,
                  secondarySpecialty,
                  secondarySpecialtyOptions,
                  expertise,
                  expertiseOptions,
                  titleRequired,
                  companyRequired,
                  universityRequired,
                  graduationYear,
                  graduationYearOptions,
                  licensesAndCertification,
                  licensesAndCertificationOptions,
                  yearsOfExperience,
                  yearsOfExperienceOptions,
                  salaryRequirements,
                  salaryRequirementsOptions,
                  program,
                  programOptions,
                  style,
                  styleOptions,
                  availability,
                  availabilityOptions,
                  openTo,
                  openToOptions,
                  workplacePreference,
                  workplacePreferenceOptions,
                  form,
                  setUploadedFile,
                  profileImageRequired,
                  chooseLogoLabel,
                  passwordValidators,
                  confirmPasswordValidators,
                  submitInProgress,
                  submitDisabled,
                  submitReady,
                  saveActionMsg,
                  dispatch,
                  history,
                  routes,
                  setLoadingPage,
                };
                const sectionComponents = getSectionComponents(sectionComponentProps);

                return (
                  <Form className={classes} onSubmit={handleSubmit}>
                    {sectionComponents}
                  </Form>
                );
              }}
            />
          </div>
        ) : (
          <div className={css.loadingPageContent}>
            <H2 as="h6">
              <FormattedMessage id="CustomLandingPage.loadingPage" />
            </H2>
            <div>
              <FormattedMessage id="CustomLandingPage.loadingPageDescription" />
            </div>
            <IconSpinner className={css.iconSpinner} />
          </div>
        )}

        {publishInProgress &&
          createPortal(
            <div className={css.publishInProgress}>
              <div className={css.publishInProgressContent}>
                <IconSpinner className={css.publishInProgressIcon} />
                <span className={css.publishInProgressText}>
                  <FormattedMessage id="CreateCompanyWizard.publishInProgress" />
                </span>
              </div>
            </div>,
            document.getElementById(PORTAL_ROOT_CONTAINER_ID)
          )}
      </LayoutSingleColumn>
    </Page>
  );
};

TalentSignUpPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const page = state.CreateTalentPage;
  const { isEmailTaken } = state.auth;
  return {
    page,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.user.currentUser,
    signupError: state.auth.signupError,
    scrollingDisabled: isScrollingDisabled(state),
    isEmailTaken: isEmailTaken,
    talentProfileData: state.CreateTalentPage.profileData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onEmailTaken: email => dispatch(emailTaken(email)),
  onCreateTalent: data => dispatch(requestCreateTalent(data)),
  updateorcreateMailChimpMember: values => dispatch(updateorcreateMailChimpMember(values)),
});

const TalentSignUpPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(TalentSignUpPageComponent);

export default TalentSignUpPage;
