import React, { useRef } from 'react';

import { OnChange } from 'react-final-form-listeners';
import { FormattedMessage } from 'react-intl';
import vectorSVG from '../../assets/Vector.svg';
import {
  FieldBirthdayInput,
  FieldFileUpload,
  FieldPhoneNumberInput,
  FieldTextInput,
} from '../../components';
import { getPlaceDetails } from '../../util/googleMaps';
import { createResourceLocatorString } from '../../util/routes';
import * as validators from '../../util/validators';
import { experienceRangeValues, formatSalaryRanges } from '../AboutPage/SectionHome';
import {
  saveProfileDataToLocalStorage as saveTalentProfileDataToLocalStorage,
  uploadFiletoS3,
} from '../CreateTalentPage/CreateTalentPage.duck';
import { TalentFormContent } from './TalentSignUpPage';
import css from './TalentSignUpPage.module.css';

const TalentSignUpBasicForm = props => {
  const {
    sectionId,
    formId,
    firstNameRequired,
    lastNameRequired,
    intl,
    onEmailChangeWithDebounce,
    emailRequired,
    emailValid,
    values,
    dispatch,
    history,
    routes,
    setLoadingPage,
  } = props;

  const fileRef = useRef(null);
  const { firstName, lastName, email } = values;

  return (
    <TalentFormContent
      id={sectionId}
      title={intl.formatMessage({
        id: 'TalentSignUpPage.TalentSignUpBasicForm.title',
      })}
    >
      <div className={css.fieldsWrapper}>
        {/* <div>
          <div className={css.autofillin}>
            <div className={css.autofillinFlex}>
              <img src={vectorSVG} />
              <FormattedMessage id="SignupForm.autofillin" />
            </div>
            <div>
              <FormattedMessage id="SignupForm.autofillinDescription" />
            </div>
            <div>
              <FieldFileUpload
                name="file"
                inputRef={fileRef}
                type="file"
                id="file"
                onChange={async e => {
                  const file = e.target.files[0];

                  setLoadingPage(true);
                  if (file) {
                    Promise.all([dispatch(uploadFiletoS3(file))]).then(response => {
                      const { status, content, s3URL, error } = response[0];

                      if (error) {
                        setLoadingPage(false);
                        setTimeout(() => {
                          alert('Something is wrong, please contact support');
                        }, 500);
                      } else {
                        const {
                          basic_info,
                          current_work,
                          specifics,
                          skills_and_styles,
                          finishing_touches,
                        } = content || {};

                        if (status !== 'FAILED') {
                          const getLocation = current_work?.current_location
                            ? getPlaceDetails(current_work.current_location)
                            : null;
                          const openTo =
                            finishing_touches.seeking_full_time &&
                            finishing_touches.seeking_part_time
                              ? 'both'
                              : finishing_touches.seeking_full_time
                              ? 'full-time'
                              : finishing_touches.seeking_part_time
                              ? 'part-time'
                              : null;
                          let updateValues = {
                            firstName,
                            lastName,
                            email,
                            registerType: 'candidate',
                            phone: basic_info.phone,
                            birthday: basic_info.date_of_birth,
                            primarySpecialty: current_work.primary_specialty,
                            secondarySpecialty: current_work.secondary_specialties,
                            expertise: current_work.expertise,
                            industry: current_work.current_position,
                            location: getLocation,
                            title: specifics.current_position,
                            company: specifics.current_company,
                            university: specifics.university,
                            degree: specifics.degree,
                            graduationYear: [specifics.education_graduation_year],
                            experience: specifics.experience_level
                              ? [experienceRangeValues([specifics.experience_level])]
                              : [],
                            salaryRequirements: specifics.desired_salary
                              ? [formatSalaryRanges([specifics.desired_salary])]
                              : [],
                            licensesAndCertification: specifics.licenses,
                            program: skills_and_styles.skills,
                            style: skills_and_styles.design_styles,
                            customStyles: skills_and_styles.custom_styles,
                            availability: finishing_touches.availability_status,
                            workplacePreference: finishing_touches.workplace_preference,
                            s3URL,
                          };
                          if (openTo !== null && openTo) updateValues.openTo = openTo;
                          Promise.all([
                            dispatch(saveTalentProfileDataToLocalStorage(updateValues)),
                          ]);

                          history.push(
                            createResourceLocatorString('NewTalentPage', routes, updateValues, {})
                          );
                        }
                      }
                    });
                  }
                }}
              />
            </div>
          </div>
        </div> */}
        <label>Personal Info</label>
        <div className={css.fieldFlex}>
          <FieldTextInput
            id={`${formId}.firstName`}
            name="firstName"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.firstNamePlaceholder',
            })}
            validate={firstNameRequired}
          />
          <FieldTextInput
            id={`${formId}.lastName`}
            name="lastName"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.lastNamePlaceholder',
            })}
            validate={lastNameRequired}
          />
        </div>

        <div className={css.fieldFlex}>
          <FieldTextInput
            id={`${formId}.email`}
            name="email"
            className={css.field}
            type="email"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.emailPlaceholder',
            })}
            validate={validators.composeValidators(emailRequired, emailValid)}
          />
          <OnChange name="email">
            {(value, previous) => onEmailChangeWithDebounce({ email: value })}
          </OnChange>
          <FieldPhoneNumberInput
            id={`${formId}.phone`}
            name="phone"
            className={css.field}
            type="text"
            placeholder={intl.formatMessage({
              id: 'CreateTalentBasicsForm.phonePlaceholder',
            })}
          />
        </div>

        {/* <div className={css.field}>
          <label>Date of Birth</label>
          <FieldBirthdayInput
            id={`${formId}.birthday`}
            name="birthday"
            valueFromForm={values.birthday}
          />
        </div> */}
      </div>
    </TalentFormContent>
  );
};

export default TalentSignUpBasicForm;
