/**
 * Export loadData calls from ducks modules of different containers
 */
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader, loadData } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as CreateCompanyPageLoader } from './CreateCompanyPage/CreateCompanyPage.duck';
import { loadData as CreateTalentPageLoader } from './CreateTalentPage/CreateTalentPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as LoginPageLoader } from './LoginPage/LoginPage.duck';
import { loadData as NameChangePageLoader } from './NameChangePage/NameChangePage.duck';
import { loadData as ProfileCompletePageLoader } from './ProfileCompletePage/ProfileCompletePage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import { loadData as SearchPageLoader } from './SearchPage/SearchPage.duck';
import { loadData as SmartSearchPageLoader } from './SmartSearchPage/SmartSearchPage.duck';

const getPageDataLoadingAPI = () => {
  return {
    LoginPage: {
      loadData: LoginPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    CreateCompanyPage: {
      loadData: CreateCompanyPageLoader,
    },
    CreateTalentPage: {
      loadData: CreateTalentPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    SearchPage: {
      loadData: SearchPageLoader,
    },
    NameChangePage: {
      loadData: NameChangePageLoader,
    },
    ProfileCompletePage: {
      loadData: ProfileCompletePageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    LandingPage: {
      loadData: LandingPageLoader,
    },
    SmartSearchPage: {
      loadData: SmartSearchPageLoader,
    },
  };
};

export default getPageDataLoadingAPI;
