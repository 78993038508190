import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { debounce } from 'lodash';
import { bool, number, object, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { compose } from 'redux';
import {
  ChangeableImageFromFile,
  Footer,
  Form,
  IconBack,
  IconSpinner,
  LayoutSingleColumn,
  NamedRedirect,
  Page,
} from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { emailTaken } from '../../ducks/auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { ensureCurrentUser } from '../../util/data';
import { intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/uiHelpers';
import { generateRandomUUID } from '../../util/uuid';
import * as validators from '../../util/validators';
import AsideNav, { BASICS, INDUSTRY, PORTFOLIO } from '../AboutPage/AsideNav';
import { requestCreateCompany } from '../CreateCompanyPage/CreateCompanyPage.duck';
import { updateorcreateMailChimpMember } from '../CreateTalentPage/CreateTalentPage.duck';
import IconPlus from '../SearchPage/IconPlus/IconPlus';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import CompanySignUpBasicForm from './CompanySignUpBasicForm';
import css from './CompanySignUpPage.module.css';
import CompanySignUpPortfolioForm from './CompanySignUpPortfolioForm';
import CompanytSignUpIndustryForm from './CompanytSignUpIndustryForm';

const MAX_MOBILE_SCREEN_WIDTH = 769;
const TAB_LISTING_FIELD_IDS = [
  'companySize',
  'industry',
  'primarySpecialty',
  'secondarySpecialty',
  'style',
  'ad100',
  'wfhPolicy',
  'openTo',
  'availability',
];

const MIN_IMAGES = 1;
const DEBOUNCE_WAIT_TIME = 200;
const PORTAL_ROOT_CONTAINER_ID = 'portal-root';
const NAME_MAX_LENGTH = 60;

const getSectionComponents = componentProps => [
  <CompanySignUpBasicForm key={BASICS} sectionId={BASICS} {...componentProps} />,
  <CompanytSignUpIndustryForm key={INDUSTRY} sectionId={INDUSTRY} {...componentProps} />,
  <CompanySignUpPortfolioForm key={PORTFOLIO} sectionId={PORTFOLIO} {...componentProps} />,
];

export const CompanyFormContent = props => {
  const { title, children, id } = props;
  return (
    <div id={id} className={css.formContent}>
      <div className={css.formContentTitle}>{title}</div>
      {children}
    </div>
  );
};

const CompanySignUpPageComponent = props => {
  const {
    intl,
    viewport,
    scrollingDisabled,
    initialValues,
    isEmailTaken,
    companyProfileData,
    onCreateCompany,
    currentUser,
    page,
    isAuthenticated,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;

  if (isAuthenticated && currentUserLoaded && !page.updateInProgress) {
    return <NamedRedirect name="ProfileCompletePage" params={{ type: 'company' }} />;
  }

  const uploadedFileFromInitialValues = initialValues?.profileImage
    ? initialValues?.profileImage
    : null;

  const uploadedFilesFromInitialValues = initialValues?.images ? initialValues?.images : [];
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [publishInProgress, setPublishInProgress] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (companyProfileData  && companyProfileData.email) {
      dispatch(emailTaken({ email: companyProfileData.email }));
    }
  }, []);

  const handleSubmit = values => {
    const { email } = values;

    if (validators.emailFormatValidWithoutForm(email)) {
      dispatch(emailTaken(values));
    }
  };

  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  const [uploadedFile, setUploadedFile] = useState(uploadedFileFromInitialValues);
  const [files, setFiles] = useState(uploadedFilesFromInitialValues);

  const config = useConfiguration();
  const listingConfig = config.listing;
  const listingFields = listingConfig.listingFields.filter(l =>
    TAB_LISTING_FIELD_IDS.includes(l.key)
  );

  const industry = listingFields.find(l => l.key === 'industry');
  const industryOptions = industry ? industry.enumOptions : [];

  const expertise = listingFields.find(l => l.key === 'expertise');
  const expertiseOptions = expertise ? expertise.enumOptions : [];

  const primarySpecialty = listingFields.find(l => l.key === 'primarySpecialty');
  const primarySpecialtyOptions = primarySpecialty ? primarySpecialty.enumOptions : [];

  const secondarySpecialty = listingFields.find(l => l.key === 'secondarySpecialty');
  const secondarySpecialtyOptions = secondarySpecialty ? secondarySpecialty.enumOptions : [];

  const graduationYear = listingFields.find(l => l.key === 'graduationYear');
  const graduationYearOptions = graduationYear ? graduationYear.enumOptions : [];

  const yearsOfExperience = listingFields.find(l => l.key === 'experience');
  const yearsOfExperienceOptions = yearsOfExperience ? yearsOfExperience.enumOptions : [];

  const salaryRequirements = listingFields.find(l => l.key === 'salaryRequirements');
  const salaryRequirementsOptions = salaryRequirements ? salaryRequirements.enumOptions : [];

  const licensesAndCertification = listingFields.find(l => l.key === 'licensesAndCertification');
  const licensesAndCertificationOptions = licensesAndCertification
    ? licensesAndCertification.enumOptions
    : [];

  const program = listingFields.find(l => l.key === 'program');
  const programOptions = program ? program.enumOptions : [];

  const style = listingFields.find(l => l.key === 'style');
  const styleOptions = style ? style.enumOptions : [];

  const availability = listingFields.find(l => l.key === 'availability');
  const availabilityOptions = availability ? availability.enumOptions : [];

  const workplacePreference = listingFields.find(l => l.key === 'workplacePreference');
  const workplacePreferenceOptions = workplacePreference ? workplacePreference.enumOptions : [];

  const openTo = listingFields.find(l => l.key === 'openTo');
  const openToOptions = openTo ? openTo.enumOptions : [];

  const newsletter = listingFields.find(l => l.key === 'newsletter');
  const newsletterOptions = newsletter ? newsletter.enumOptions : [];

  const marketplaceName = config.marketplaceName;
  const schemaTitle = 'Talent Page ';
  const schemaDescription = 'Talent SignUp Flow';
  const NAV = [BASICS, INDUSTRY, PORTFOLIO];
  const isMobileLayout = viewport.width <= MAX_MOBILE_SCREEN_WIDTH;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbarClassName={css.topbar}
        topbar={<TopbarContainer />}
        footer={<Footer />}
      >
        <div className={css.content}>
          <div className={css.asideContent}>
            <AsideNav intl={intl} isMobileLayout={isMobileLayout} NAV={NAV} />
          </div>
          <div className={css.backContent}>
            <div className={css.iconBack} onClick={() => history.goBack()}>
              <IconBack />{' '}
            </div>
          </div>
          <FinalForm
            className={css.sections}
            intl={intl}
            onEmailChangeWithDebounce={handleChangeWithDebounce}
            saveActionMsg="SUBMIT APPLICATION"
            initialValues={companyProfileData}
            onSubmit={values => {
              try {
                setSubmitInProgress(true);
                const {
                  id,
                  name,
                  email,
                  password,
                  companySize,
                  website,
                  instagram,
                  location,
                  secondaryLocation,
                  industry,
                  primarySpecialty,
                  secondarySpecialty,
                  description,
                  style,
                  ad100,
                  wfhPolicy,
                  openTo,
                  profileImage,
                  images,
                  newsletter,
                } = values;

                const { selectedPlace } = location;
                const geolocation = selectedPlace?.origin;
                const googleMapsUrl = selectedPlace?.url;

                if (!geolocation || !googleMapsUrl) {
                  setSubmitError(true);
                  setSubmitInProgress(false);
                  alert('Location not specific, please fill in again!');
                } else {
                  const splitAddr1 = selectedPlace?.address.split(',');
                  const address = splitAddr1
                    ? {
                        addressLine1: splitAddr1[0],
                        city: splitAddr1[1],
                        zip: splitAddr1[2],
                      }
                    : {};

                  const { selectedPlace: secondarySelectedPlace } = values?.secondaryLocation || {};
                  const splitAddr2 = secondarySelectedPlace?.address.split(',');
                  const secondaryAddress = splitAddr2
                    ? {
                        addressLine1: splitAddr2[0],
                        city: splitAddr2[1],
                        zip: splitAddr2[2],
                      }
                    : {};

                  const updateValues = {
                    id: id ? id : generateRandomUUID(),
                    name,
                    email,
                    address,
                    secondaryAddress,
                    companySize,
                    website,
                    instagram,
                    geolocation,
                    googleMapsUrl,
                    location,
                    secondaryLocation,
                    industry,
                    primarySpecialty,
                    secondarySpecialty,
                    description,
                    style,
                    ad100,
                    wfhPolicy: wfhPolicy ? [wfhPolicy] : null,
                    openTo,
                    profileImage,
                    images,
                    password,
                  };

                  setSubmitInProgress(false);

                  const objectHaveValues = Object.fromEntries(
                    Object.entries(updateValues).filter(([_, value]) => value !== undefined)
                  );

                  if (newsletter && Array.isArray(newsletter) && newsletter.length > 0) {
                    const mailchimpMember = {
                      ...values,
                      step: 3,
                      registertype: 'Clients',
                    };

                    dispatch(updateorcreateMailChimpMember(mailchimpMember));
                  } else {
                    // step 4 is stand for unsubsribe
                    const mailchimpMember = {
                      ...values,
                      step: 4,
                      registertype: 'Clients',
                    };

                    dispatch(updateorcreateMailChimpMember(mailchimpMember));
                  }
                  setPublishInProgress(true);
                  onCreateCompany(objectHaveValues);
                }
              } catch (e) {
                console.log(e);
                setSubmitError(true);
                setSubmitInProgress(false);
              }
            }}
            mutators={{ ...arrayMutators }}
            validate={() => {
              const errors = {};
              if (isEmailTaken) {
                const emailTakenMessage = intl.formatMessage({
                  id: 'CreateCompanyBasicsForm.emailTaken',
                });
                errors.email = emailTakenMessage;
              }
              return errors;
            }}
            render={formRenderProps => {
              const {
                className,
                disabled,
                ready,
                formId,
                handleSubmit,
                onRedirectToPreviousTab,
                onEmailChangeWithDebounce,
                isEmailTaken,
                intl,
                invalid,
                pristine,
                form,
                values,
                saveActionMsg,
                updated,
                updateInProgress,
                fetchErrors,
                viewport,
                initialValues,
              } = formRenderProps;

              useEffect(() => {
                if (isEmailTaken) {
                  const { email } = initialValues || {};
                  // You might also want to set it as touched to trigger validation
                  form.blur('email'); // Ensure it is considered as touched
                  form.focus('email'); // Optionally focus back for better UX
                }
              }, [isEmailTaken]);

              const nameRequiredMessage = intl.formatMessage({
                id: 'CreateCompanyBasicsForm.nameRequired',
              });
              const maxLengthMessage = intl.formatMessage(
                { id: 'CreateCompanyBasicsForm.maxLength' },
                {
                  maxLength: NAME_MAX_LENGTH,
                }
              );
              const maxLength60Message = validators.maxLength(maxLengthMessage, NAME_MAX_LENGTH);

              const emailRequiredMessage = intl.formatMessage({
                id: 'CreateCompanyBasicsForm.emailRequired',
              });
              const emailRequired = validators.required(emailRequiredMessage);
              const emailInvalidMessage = intl.formatMessage({
                id: 'CreateCompanyBasicsForm.emailInvalid',
              });
              const emailValid = validators.emailFormatValid(emailInvalidMessage);

              const config = useConfiguration();
              const listingConfig = config.listing;
              const listingFields = listingConfig.listingFields.filter(l =>
                TAB_LISTING_FIELD_IDS.includes(l.key)
              );

              const companySize = listingFields.find(l => l.key === 'companySize');
              const companySizeOptions = companySize?.enumOptions ? companySize.enumOptions : [];

              const industry = listingFields.find(l => l.key === 'industry');
              const industryOptions = industry ? industry.enumOptions : [];

              const primarySpecialty = listingFields.find(l => l.key === 'primarySpecialty');
              const primarySpecialtyOptions = primarySpecialty ? primarySpecialty.enumOptions : [];

              const secondarySpecialty = listingFields.find(l => l.key === 'secondarySpecialty');
              const secondarySpecialtyOptions = secondarySpecialty
                ? secondarySpecialty.enumOptions
                : [];

              const style = listingFields.find(l => l.key === 'style');
              const styleOptions = style ? style.enumOptions : [];

              const openTo = listingFields.find(l => l.key === 'openTo');
              const openToOptions = openTo ? openTo.enumOptions : [];

              const ad100 = listingFields.find(l => l.key === 'ad100');
              const ad100Options = ad100 ? ad100.enumOptions : [];

              const wfhPolicy = listingFields.find(l => l.key === 'wfhPolicy');
              const wfhPolicyOptions = wfhPolicy ? wfhPolicy.enumOptions : [];

              const imageId = uploadedFile && String(uploadedFile.lastModified);
              const imageFromFile = uploadedFile ? (
                <ChangeableImageFromFile
                  id={imageId}
                  rootClassName={css.imageFromFile}
                  aspectWidth={1}
                  aspectHeight={1}
                  file={uploadedFile}
                />
              ) : null;

              const chooseLogoLabel = imageFromFile ? (
                <div className={css.imageFromFileWrapper}>
                  {imageFromFile}
                  <div className={css.imageFromFileLabelWrapper}>
                    <span className={css.imageFromFileLabel}>
                      <FormattedMessage id="CreateCompanyPortfolioForm.changeLogoLabel" />
                    </span>
                  </div>
                </div>
              ) : (
                <div className={css.uploadZone}>
                  <span className={css.uploadZoneLabel}>
                    <FormattedMessage id="CreateCompanyPortfolioForm.chooseLogoLabel" />
                  </span>
                  <span className={css.uploadZoneIconWrapper}>
                    <IconPlus className={css.uploadZoneIcon} isOpen={false} />
                  </span>
                </div>
              );

              const profileImageRequiredMessage = intl.formatMessage({
                id: 'CreateCompanyPortfolioForm.profileImageRequired',
              });
              const profileImageRequired = validators.required(profileImageRequiredMessage);

              // password
              const passwordRequiredMessage = intl.formatMessage({
                id: 'CreateCompanyBasicsForm.passwordRequired',
              });
              const passwordMinLengthMessage = intl.formatMessage(
                {
                  id: 'CreateCompanyBasicsForm.passwordTooShort',
                },
                {
                  minLength: validators.PASSWORD_MIN_LENGTH,
                }
              );
              const passwordMaxLengthMessage = intl.formatMessage(
                {
                  id: 'CreateCompanyBasicsForm.passwordTooLong',
                },
                {
                  maxLength: validators.PASSWORD_MAX_LENGTH,
                }
              );
              const passwordMinLength = validators.minLength(
                passwordMinLengthMessage,
                validators.PASSWORD_MIN_LENGTH
              );
              const passwordMaxLength = validators.maxLength(
                passwordMaxLengthMessage,
                validators.PASSWORD_MAX_LENGTH
              );
              const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
              const passwordValidators = validators.composeValidators(
                passwordRequired,
                passwordMinLength,
                passwordMaxLength
              );

              const confirmPasswordRequiredMessage = intl.formatMessage({
                id: 'CreateCompanyBasicsForm.confirmPasswordRequired',
              });
              const confirmPasswordInvalidMessage = intl.formatMessage({
                id: 'CreateCompanyBasicsForm.confirmPasswordInvalid',
              });
              const confirmPasswordRequired = validators.required(confirmPasswordRequiredMessage);
              const confirmPasswordInvalid = validators.passwordMatch(
                confirmPasswordInvalidMessage,
                values.password
              );
              const confirmPasswordValidators = validators.composeValidators(
                confirmPasswordRequired,
                confirmPasswordInvalid
              );

              const imagesFromValues = values.images;
              const hasEnoughImages = imagesFromValues?.length >= MIN_IMAGES;

              const classes = classNames(css.root, className);
              const submitReady = (updated && pristine) || ready;
              const submitInProgress = updateInProgress;
              const submitDisabled = invalid || disabled || submitInProgress || isEmailTaken;

              const sectionComponentProps = {
                marketplaceName,
                isMobileLayout,
                formId,
                emailRequired,
                emailValid,
                intl,
                intl,
                onEmailChangeWithDebounce,
                values,
                initialValues,
                form,
                maxLength60Message,
                nameRequiredMessage,
                companySize,
                companySizeOptions,
                isMobileLayout,
                industry,
                industryOptions,
                primarySpecialty,
                primarySpecialtyOptions,
                secondarySpecialty,
                secondarySpecialtyOptions,
                style,
                styleOptions,
                wfhPolicy,
                wfhPolicyOptions,
                openTo,
                openToOptions,
                ad100,
                ad100Options,
                form,
                setUploadedFile,
                profileImageRequired,
                chooseLogoLabel,
                files,
                setFiles,
                passwordValidators,
                confirmPasswordValidators,
                submitInProgress,
                submitDisabled,
                submitReady,
                saveActionMsg,
                availability,
                availabilityOptions,
              };
              const sectionComponents = getSectionComponents(sectionComponentProps);

              return (
                <Form className={classes} onSubmit={handleSubmit}>
                  {sectionComponents}
                </Form>
              );
            }}
          />
        </div>
        {publishInProgress &&
          createPortal(
            <div className={css.publishInProgress}>
              <div className={css.publishInProgressContent}>
                <IconSpinner className={css.publishInProgressIcon} />
                <span className={css.publishInProgressText}>
                  <FormattedMessage id="CreateCompanyWizard.publishInProgress" />
                </span>
              </div>
            </div>,
            document.getElementById(PORTAL_ROOT_CONTAINER_ID)
          )}
      </LayoutSingleColumn>
    </Page>
  );
};

CompanySignUpPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const page = state.CreateCompanyPage;
  const { isEmailTaken } = state.auth;
  
  return {
    page,
    isAuthenticated: state.auth.isAuthenticated,
    currentUser: state.user.currentUser,
    signupError: state.auth.signupError,
    scrollingDisabled: isScrollingDisabled(state),
    isEmailTaken: isEmailTaken,
    companyProfileData: state.CreateCompanyPage.profileData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onEmailTaken: email => dispatch(emailTaken(email)),
  onCreateCompany: data => dispatch(requestCreateCompany(data)),
  updateorcreateMailChimpMember: values => dispatch(updateorcreateMailChimpMember(values)),
});

const CompanySignUpPage = compose(
  withRouter,
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(CompanySignUpPageComponent);

export default CompanySignUpPage;
